import * as React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'

export const AccordionItem = ({ title, text, i, arrayLength }) => {
  // console.log('key on item', i)
  return (
    <StyledAccordionItem eventKey={i-arrayLength}>
      <Accordion.Header>
        <RichText render={title} />
      </Accordion.Header>
      <Accordion.Body>
        <RichText render={text} />
      </Accordion.Body>
    </StyledAccordionItem>
  )
}

const StyledAccordionItem = styled(Accordion.Item)`
  h4 {
    font-size: 1.8rem;
  }

  h5 em {
    font-size: 1.6rem;
    color: green;
  }

  ul li {
    font-size: 1.2rem;
  }
  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {
    ul li {
      font-size: 1.2rem !important;
    }
  }
`
