import * as React from 'react'
import { useState } from 'react'
import { graphql } from 'gatsby'
import MainLayoutPart from '../../components/MainLayoutPart'
import Form from 'react-bootstrap/Form'
import { StyledFormControl, StyledFormLabel, style } from './StyledContactForm'
import StyledButton from '../../styledComponents/StyledButton'
import SectionTitle from '../../components/SectionTitle'
import { OneColSection } from '../../components/OneColSection'

export const ContactForm = ({ slice }) => {
    console.log('ContactForm data', slice)

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
        }
        // Uncoment for test purpose
        // event.preventDefault();
        setValidated(true);
        console.log("submittet")
    }

    const formTitle = slice.primary.form_title.raw
    const formInstructions = slice.primary.form_instructions.raw

    return (
      <>
        {/* formTitle can be null or an object with emty string */}
        {!formTitle ? null : formTitle[0].text !== '' ? (
          <SectionTitle title={formTitle} />
        ) : null}
        {formInstructions ? <OneColSection content={formInstructions} /> : null}
        <MainLayoutPart
          content={
            <Form
              // When adding new fields the version must be updated, otherwise netlify won't recognise it.
              name="contact"
              method="POST"
              data-netlify="true"
              action="/contactsuccess"
              lang="de"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              data-netlify-honeypot="bot-field"
            >

              <p hidden>
                <lable>
                  Dont fill this out: <input name="bot-field" />
                </lable>
              </p>
              {slice.items.map((field, i) => {
                console.log('form field', field)

                if (field.field_type !== 'textarea') {
                  return (
                    <Form.Group key={i}>
                      <StyledFormLabel htmlFor={field.field_name}>
                        {field.field_name}{' '}
                        {field.required === true ? ' *' : null}
                      </StyledFormLabel>
                      <StyledFormControl
                        name={field.field_name}
                        // placeholder={field.field_name}
                        required={field.required === true}
                        type={field.field_type}
                        id={field.field_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {field.invalid_feedback_text}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )
                } else {
                  return (
                    <Form.Group key={i}>
                      <StyledFormLabel htmlFor={field.field_name}>
                        {field.field_name}{' '}
                        {field.required === true ? ' *' : null}
                      </StyledFormLabel>
                      <Form.Control
                        name="message"
                        rows={3}
                        required
                        // placeholder={field.field_name}
                        style={style}
                      />
                      <Form.Control.Feedback type="invalid">
                        {field.invalid_feedback_text}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )
                }
              })}
              <StyledButton
                variant="outline-danger"
                type="submit"
              >
                {slice.primary.submitt_button_text}
              </StyledButton>
            </Form>
          }
        />
      </>
    )
}

export const query = graphql`
fragment PrismicPageDataBodyKontaktformular on PrismicPageDataBodyKontaktformular {
    primary {
            submitt_button_text
            submitted_text {
                raw
              }
              form_instructions {
                raw
              }
              form_title {
                raw
              }
            }
            items {
              field_name
              field_type
              invalid_feedback_text
              required
            }
slice_type
}
`