import React from 'react'
import { graphql } from 'gatsby'
import { OneColSection } from '../components/OneColSection'

export const Zitat = ({ slice }) => {
  console.log('Quote data', slice)
  return (
    <OneColSection
      content={slice.primary.quote}
      textAlign={slice.primary.text_align_center}
      source={slice.primary.source}
      sliceTipe={slice.slice_type}
    />
  )
}

export const query = graphql`
  fragment PrismicPageDataBodyZitat on PrismicPageDataBodyZitat {
  slice_type
    primary {
      quote
      source
      text_align_center
    }
  }
`
