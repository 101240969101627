import React from 'react'
import styled from 'styled-components'

const BlockquoteContainer = styled.div`
  width: 90%;
  margin: 2rem auto 5rem auto;
  background-color: var(--page-bg-color-dark);
  padding: 1.6rem 4.5rem 1.6rem 4.5rem;
  border-radius: 20px;

  & blockquote {
    margin: 0;
    position: relative;
  }

  & blockquote:before {
    content: open-quote;
    font-size: 4em;
    /*difiniert vertikale position des anführungszeichen */
    line-height: 0;
    position: absolute;
    left: -30px;
  }

  /* bezieht sich auf content test */
  & blockquote p {
    /* display: inline; */
  }

  /* bezieht sich auf source test */
  & blockquote cite {
    display: block;
  }

  & blockquote:after {
    content: close-quote;
    font-size: 4em;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: -2rem;
    /*     line-height: 0.1em;
    vertical-align: -0.4em; */
  }
`
function Blockquote({ content, source }) {
  return (
    <BlockquoteContainer>
      <blockquote lang="de">
        <p>{content}</p>
        <cite>{source}</cite>
      </blockquote>
    </BlockquoteContainer>
  )
}

export default Blockquote
