import * as React from 'react'
import { RichText } from 'prismic-reactjs'
import MainLayoutPart from './MainLayoutPart'

const PageTitle = ({ pageTitle }) => {

    const pageTitleContent = (<RichText render={pageTitle || []} />)

    return (
        <MainLayoutPart content={pageTitleContent} />
    )
}

const PageSubTitle = ({ pageSubTitle }) => {

    const pageSubTitleContent = (<RichText render={pageSubTitle || []} />)

    if(pageSubTitle) {
        return (
            <MainLayoutPart content={pageSubTitleContent} />
        )
    }
    return null
}

export { PageTitle, PageSubTitle }
