import styled from 'styled-components'
import Form from 'react-bootstrap/Form'

// pseudo styles on app.css
const style = {
    background: "var(--page-bg-color)",
    borderTop: 'var(--page-bg-color)',
    borderLeft: "var(--page-bg-color)",
    borderRight: "var(--page-bg-color)",
    borderRadius: 0,
    color: "var(--page-link-color)",
}

const StyledFormControl = styled(Form.Control)`
    /* .form-control::placeholder {
            color: red !important;
            opacity: 1 !important;
    } */

    background-color: var(--page-bg-color);
    border-top:  var(--page-bg-color);
    border-left:  var(--page-bg-color);
    border-right:  var(--page-bg-color);
    border-bottom: var(--page-link-color) 1px solid;
    border-radius: 0;
    color: var(--page-link-color) !important;
    width: 100%;

        &:focus{
            box-shadow: none;
            outline: none;
            background-color: var(--page-bg-color);
            border-bottom: var(--page-link-color) 2px solid;
        }
`
const StyledFormLabel = styled(Form.Label)`
    color: var(--page-text-color);
`

export { StyledFormControl, StyledFormLabel, style }