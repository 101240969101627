import * as React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { Row, Col } from 'react-bootstrap'

export const Links = ({ slice }) => {
  console.log('Links data', slice)

    const linkLists = slice.items.map((link) => (
      <li key={link.index}>
        <Link to={`/${link.link.document.uid}`}>
          {link.link.document.data.title.text}
        </Link>
      </li>
    ))

  return (
    <Row>
      <Col md={1} lg={2}></Col>
      <Col>
        <h2>{slice.primary.title.text}</h2>
        <ul>{linkLists}</ul>
      </Col>
      <Col md={1} lg={2}></Col>
    </Row>
  )
}

export const query = graphql`
  fragment PrismicPageDataBodyLinks on PrismicPageDataBodyLinks {
    slice_type
    primary {
      title {
        raw
        text
      }
    }
    items {
      link {
        document {
          ... on PrismicStunde {
            data {
              title {
                text
              }
            }
            uid
          }
          ... on PrismicAnleitung {
            uid
            data {
              title {
                text
              }
            }
          }
        }
      }
    }
  }
`
