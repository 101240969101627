import * as React from 'react'
import { Row, Col } from 'react-bootstrap'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'

const SectionTitle = ({title}) => {
    return (
        <Row className="mt-4 mt-md-5 mb-4 mb-md-5">
            <Col md={1} lg={2}></Col>
            <SectionTitleCol>
                <RichText render={title} />
            </SectionTitleCol>
            <Col md={1} lg={2}></Col>
        </Row>
    )
}

const SectionTitleCol = styled(Col)`
  h2 {
    font-size: 1.5rem;
  }

`


export default SectionTitle
