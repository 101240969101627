import * as React from 'react'
import { Row, Col } from 'react-bootstrap'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import linkStyles from './styled/linkStyles'
import styled from 'styled-components'
import Blockquote from './Blockquote'

const StyledRow = styled(Row)`
  padding-top: ${(props) => props.marginTop}px;

  p, h2, h3, h4, h5, h6, li {
    ${(props) =>
      props.textAlign ? 'text-align: center;' : 'text-align: left;'};
  }

  a {
    ${linkStyles}
  }

  margin-top: 2rem !important;

  /*     h4 {
        font-size: 15rem !important;
    } */
`

export const OneColSection = ({
  content,
  marginTop,
  textAlign,
  sliceTipe,
  source,
}) => {

  return (
    <StyledRow marginTop={marginTop} textAlign={textAlign}>
      <Col md={1} lg={2}></Col>
      {sliceTipe !== 'zitat' ? (
        <Col>
          <RichText render={content} linkResolver={linkResolver} />
        </Col>
      ) : (
        <Col>
          <Blockquote content={content} source={source}/>
        </Col>
      )}
      <Col md={1} lg={2}></Col>
    </StyledRow>
  )
}
