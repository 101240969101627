import * as React from 'react'
import { graphql } from 'gatsby'
import SectionTitle from '../components/SectionTitle'
import ListGroup from 'react-bootstrap/ListGroup'
import MainLayoutPart from '../components/MainLayoutPart'
import { date, day, time } from '../utils/dateFn'

export const Events = ({ slice }) => {
    console.log('Events data', slice)

    const listGroup = (
            <ListGroup>
            {slice.items.map((item, i) => (
                <ListGroup.Item key={i}>{date(item.start_timestamp)} | {time(item.start_timestamp) }</ListGroup.Item>
            ))}
            </ListGroup>
    )
    
    return (
        <>
            <SectionTitle title={slice.primary.section_title.raw} />
            <MainLayoutPart content={listGroup} />
        </>
    )
}

export const query = graphql`
fragment PrismicPageDataBodyEvents on PrismicPageDataBodyEvents {
slice_type
primary {
        section_title {
            raw
        }
        }
        items {
        location
        start_timestamp
        }
}
`